<template>
    <div>
        <!-- <div class="order-area">
            <div class="timeBox">
                <div class="xiang" @click="scollHandle(1)"><i class="el-icon-d-arrow-left"></i></div>
                <div class="scrollBox">
                    <div class="list"
                        :style="'width:' + 7.8 * timeList.length + 'vw;transform: translateX(-' + scrollLeft + 'vw);'">
                        <div class="timeItem" v-for="(item, index) in timeList" :key="index" @click="selectDay(index)">
                            <div :class="'cart ' + (activeIndex == index ? 'active' : '')">
                                <div>{{ item.weekStr }}</div>
                                <div>{{ item.month }}月{{ item.day }}日</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="xiang" @click="scollHandle(2)"><i class="el-icon-d-arrow-right"></i></div>
            </div>
            <div class="lists" v-for="(item, index) in lists" :key="index">
                <div class="title">
                    <div class="line"></div>
                    <div class="show-text"> {{ item.venue.venueName }} |{{ item.venue.matchType }}人制 | {{
                        item.venue.areaType }}
                    </div>
                </div>
                <div class="order-lists">
                    <div v-for="(item, index) in item.lists" :key="index">
                        <div class="list-item">
                            <div class="type">
                                <img v-if="item.bookType === 1"
                                    src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/314-pinchang.png" />
                                <img v-if="item.bookType === 2"
                                    src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/313-feipan.png" />
                                <img v-if="item.bookType === 0"
                                    src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/312-zhengchang.png" />
                            </div>
                            <div class="info">
                                <div class="time">
                                    <div class="headline">时段</div>
                                    <div class="value">{{ item.beginTime }}-{{ item.endTime }}</div>
                                </div>
                                <div class="price">
                                    <div class="headline">费用</div>
                                    <div class="value">
                                        ￥{{ item.price }}/
                                        <span v-if="item.bookType == 0"> 场</span>
                                        <span v-else>人</span>
                                    </div>
                                </div>
                            </div>
                            <div class="info">
                                <div class="time">
                                    <div class="headline">订单状态</div>
                                    <span class="value" v-if="item.status == -1">人数未满，自动取消</span>
                                    <span class="value" v-if="item.status == 0">未开始({{ item.payedCount }}人已付款)</span>
                                    <span class="value" v-if="item.status == 1">不可预订，即将开始 </span>
                                    <span class="value" v-if="item.status == 2">进行中 </span>
                                    <span class="value" v-if="item.status == 3">已结束({{ item.payedCount }}人已付款) </span>
                                    <span class="value" v-if="item.status == 33">已订满({{ item.payedCount }}人已付款) </span>
                                </div>
                                <div class="price">
                                    <div class="headline">费用</div>
                                    <div class="value">
                                        ￥{{ item.price }}/
                                        <span v-if="item.bookType == 0"> 场</span>
                                        <span v-else>人</span>
                                    </div>
                                </div>
                            </div>
                            <div class="userInfo" @click="userInfo">用户信息</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="用户查看">
            <div class="info-area" v-for="(item, index) in orderUserList" :key="index">
                <img :src="item.personPic" />
                <div class="user-info">
                    <div class="user-name">{{ item.personName }}</div>
                    <div class="pay-time">
                        <span v-if="item.payStatus === 0">创建支付中</span>
                        <span v-if="item.payStatus === 10">已支付 {{ item.time }}</span>
                        <span class="orderNo">订单号：{{ item.orderNo }}</span>
                    </div>
                </div>
                <div :class="[item.payStatus === 0 || schedule.status == '1' || schedule.status == '2' || schedule.status == '3' ? 'not' : '']"
                    class="refund">
                    发起退款
                </div>
            </div>
        </el-dialog> -->
    </div>
</template>
<script>
export default {
    // data() {
    //     return {
    //         time: '',
    //         timeList: [],
    //         activeIndex: 0,
    //         scrollLeft: 0,
    //         showCalendar: false,
    //         dialogVisible: false,
    //         lists: [
    //             {
    //                 venue: {
    //                     venueName: "泡泡场地",
    //                     matchType: "2",
    //                     areaType: "室外"
    //                 },
    //                 lists: [
    //                     {
    //                         bookType: 1,
    //                         beginTime: "8:00",
    //                         endTime: "10:00",
    //                         price: "100",
    //                         status: 1,
    //                         payedCount: "5"
    //                     },
    //                     {
    //                         bookType: 0,
    //                         beginTime: "8:00",
    //                         endTime: "10:00",
    //                         price: "100",
    //                         status: 0,
    //                         payedCount: "5"
    //                     },
    //                     {
    //                         bookType: 2,
    //                         beginTime: "8:00",
    //                         endTime: "10:00",
    //                         price: "100",
    //                         status: -1,
    //                         payedCount: "5"
    //                     }
    //                 ]
    //             },
    //             {
    //                 venue: {
    //                     venueName: "泡泡场地",
    //                     matchType: "2",
    //                     areaType: "室外"
    //                 },
    //                 lists: [
    //                     {
    //                         bookType: 1,
    //                         beginTime: "8:00",
    //                         endTime: "10:00",
    //                         price: "100",
    //                     }
    //                 ]

    //             },
    //             {
    //                 venue: {
    //                     venueName: "泡泡场地",
    //                     matchType: "2",
    //                     areaType: "室外"
    //                 },
    //                 lists: [
    //                     {
    //                         bookType: 1,
    //                         beginTime: "8:00",
    //                         endTime: "10:00",
    //                         price: "100",
    //                     }
    //                 ]

    //             },
    //             {
    //                 venue: {
    //                     venueName: "泡泡场地",
    //                     matchType: "2",
    //                     areaType: "室外"
    //                 },
    //                 lists: [
    //                     {
    //                         bookType: 1,
    //                         beginTime: "8:00",
    //                         endTime: "10:00",
    //                         price: "100",
    //                     }
    //                 ]

    //             },
    //             {
    //                 venue: {
    //                     venueName: "泡泡场地",
    //                     matchType: "2",
    //                     areaType: "室外"
    //                 },
    //                 lists: [
    //                     {
    //                         bookType: 1,
    //                         beginTime: "8:00",
    //                         endTime: "10:00",
    //                         price: "100",
    //                     }
    //                 ]

    //             }
    //         ],
    //         orderUserList: [
    //             {
    //                 personPic: "https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/common/8f6f5038fce848c6833a3c22b49a3083.jpg",
    //                 personName: "泡泡",
    //                 time: "08-21 16:05",
    //                 orderDetailType: 5,
    //                 payStatus: 0,
    //                 orderNo: "CV160531400007"
    //             },
    //             {
    //                 personPic: "https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/common/8f6f5038fce848c6833a3c22b49a3083.jpg",
    //                 personName: "泡泡",
    //                 time: "08-21 16:05",
    //                 orderDetailType: 5,
    //                 payStatus: 10,
    //                 orderNo: "CV160531400007"
    //             },
    //             {
    //                 personPic: "https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/common/8f6f5038fce848c6833a3c22b49a3083.jpg",
    //                 personName: "泡泡",
    //                 time: "08-21 16:05",
    //                 orderDetailType: 5,
    //                 payStatus: 0,
    //                 orderNo: "CV160531400007"
    //             },
    //             {
    //                 personPic: "https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/common/8f6f5038fce848c6833a3c22b49a3083.jpg",
    //                 personName: "泡泡",
    //                 time: "08-21 16:05",
    //                 orderDetailType: 5,
    //                 payStatus: 10,
    //                 orderNo: "CV160531400007"
    //             },
    //         ],
    //         schedule: {
    //             status: 3
    //         }
    //     };
    // },
    // mounted() {
    //     this.getTimeList()
    // },
    // methods: {
    //     // 时间轴
    //     getTimeList() {
    //         let week = new Date().getDay()
    //         let arr = []
    //         if (week == 1) {
    //             arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
    //         } else if (week == 2) {
    //             arr = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    //         } else if (week == 3) {
    //             arr = [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    //         } else if (week == 4) {
    //             arr = [-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    //         } else if (week == 5) {
    //             arr = [-4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    //         } else if (week == 6) {
    //             arr = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8]
    //         } else if (week == 0) {
    //             arr = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7]
    //         }
    //         this.activeIndex = arr.findIndex(item => item === 0)
    //         this.timeList = []
    //         arr.forEach(item => {
    //             this.timeList.push(this.getTime(item))
    //         })
    //         this.selectDay(this.activeIndex)
    //         console.log('初始化时间数组-----', this.timeList);
    //     },
    //     getTime(timeIndex) {
    //         let time = +new Date + (timeIndex * 24 * 60 * 60 * 1000)
    //         let date = new Date(time)
    //         let week = date.getDay()
    //         let year = date.getFullYear()
    //         let month = date.getMonth() + 1
    //         let day = date.getDate()
    //         let weekStr = ''
    //         if (week == 1) {
    //             weekStr = '星期一'
    //         } else if (week == 2) {
    //             weekStr = '星期二'
    //         } else if (week == 3) {
    //             weekStr = '星期三'
    //         } else if (week == 4) {
    //             weekStr = '星期四'
    //         } else if (week == 5) {
    //             weekStr = '星期五'
    //         } else if (week == 6) {
    //             weekStr = '星期六'
    //         } else if (week == 0) {
    //             weekStr = '星期日'
    //         }
    //         let timeData = {
    //             year,
    //             month,
    //             day,
    //             week,
    //             weekStr,
    //             timeIndex
    //         }
    //         return timeData
    //     },
    //     addTime() {
    //         let arr = []
    //         let num = this.timeList[this.timeList.length - 1].timeIndex
    //         for (let i = 1; i < 8; i++) {
    //             arr.push(num + i)
    //         }
    //         arr.forEach(item => {
    //             this.timeList.push(this.getTime(item))
    //         })
    //     },
    //     scollHandle(type) {
    //         if (type == 1) {   //向左
    //             if (this.scrollLeft - 54.6 > 0) {
    //                 this.scrollLeft -= 54.6
    //             } else {
    //                 this.scrollLeft = 0
    //             }
    //         } else {           //向右
    //             this.scrollLeft += 54.6
    //             this.addTime()
    //         }
    //     },
    //     selectDay(index) {
    //         this.activeIndex = index
    //         let day = this.timeList[index]
    //         let str = day.year + '-' + (day.month < 10 ? '0' + day.month : day.month) + '-' + (day.day < 10 ? '0' + day.day : day.day)
    //         this.time = str
    //     },
    //     // 查看用户信息
    //     userInfo() {
    //         this.dialogVisible = true
    //     }
    // }
};
</script>
<style lang="scss" scoped>
.order-area {
    margin-top: 3.2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

//时间轴
.timeBox {
    margin: auto;
    width: 84vw;
    height: 8.8vh;
    font-size: 1.5vh;
    background-color: #5D9FE9;
    border-radius: 1vh;
    display: flex;
    overflow: hidden;

    .xiang {
        width: 3.6vw;
        line-height: 11.8vh;
        text-align: center;
    }

    .scrollBox {
        width: 100vw;
        overflow: hidden;

        .list {
            display: flex;
            transition: transform 1s;

            .timeItem {
                width: 7.8vw;
                height: 11.8vh;
                border-left: 0.1vw solid #a9afb9;
                padding: 1vh 0;

                .cart {
                    width: 6.8vw;
                    margin: 0 auto;
                    line-height: 3.5vh;
                    padding: 1.4vh 0;
                    text-align: center;
                    border-radius: 0.6vh;
                    color: #fff;
                }

                .active {
                    background: #fff;
                    color: #222220;
                }

            }
        }
    }

}

// 订单列表
.lists {
    flex: auto;
    overflow-y: auto;
    margin-bottom: 1vh;
}

.title {
    display: flex;
    align-items: center;
    margin: 2vh 1vw;
    height: 5vh;
}

.title .line {
    width: 0.5vw;
    height: 3vh;
    border-radius: 1.5vh;
    margin-right: 0.5vw;
    background-color: #134f7a;
}

.title .show-text {
    font-weight: bold;
    font-size: 1.8vh;
}

.order-lists {
    background-color: #ffffff;
    border-radius: 1vh;
    margin: 0 0.8vw;
}

.list-item {
    display: flex;
    align-items: center;
    padding: 0 0.5vw;
    margin-top: 1vh;
    border-bottom: 1px solid #f2f3f5;
}

.list-item:nth-of-type(1) {
    border-top: none;
}

.list-item .type {
    margin-right: 1vw;
}

.lists .list-item .type img {
    width: 3vw;
    height: 6.3vh;
}

.list-item .info {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.5vh;
}

.info .price,
.info .time {
    height: 2vh;
    display: flex;
    align-items: center;
    margin: 1vh;
    text-align: center;
}

.info .price .headline,
.info .time .headline {
    width: 3vw;
    height: 2.5vh;
    background-color: #e0e0e0;
    padding: 0.02vh 0.02vw;
    color: #000;
}

.info .price .value,
.info .time .value {
    width: 8vw;
    overflow: hidden;
    white-space: nowrap;
    padding: 5rpx;
    border: 1px solid #e0e0e0;
}

.userInfo {
    width: 3.8vw;
    height: 3vh;
    line-height: 3vh;
    border-radius: 0.5vh;
    background-color: #134f7a;
    text-align: center;
    font-size: 1.5vh;
    color: #fff;
    margin-right: 2vw;
}

.orderNo {
    margin-left: 1vw;
}

.info-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
}

.user-info {
    flex: auto;
    margin-left: 0.5vw;
    display: flex;
    flex-direction: column;
    font-size: 1.5vh;
    line-height: 2.5vh;
}

.info-area img {
    width: 3vw;
    height: 6.3vh;
    padding: 0.5vh 0.2vw;
}

.refund {
    font-size: 1.3vh;
    background-color: #bb230e;
    color: #fff;
    height: 3vh;
    width: 3vw;
    border-radius: 0.8vh;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>